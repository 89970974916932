this.JST = {"select": function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="custom-select">\n    <div class="backdrop"></div>\n    <div class="selector" data-toggle-custom-select>\n        <ul>\n            ';
 if (placeholder) { ;
__p += '\n                <li class="placeholder">\n                    ' +
__e( placeholder ) +
'\n                </li>\n            ';
 } ;
__p += '\n            ';
 _.each(options, function(option) { ;
__p += '\n                <li>\n                    ' +
__e( option.text ) +
'\n                </li>\n            ';
 }); ;
__p += '\n        </ul>\n        <div class="arrow">\n            <i class="fa fa-chevron-down"></i>\n        </div>\n    </div>\n    <div class="dropdown">\n        <ul>\n            ';
 _.each(options, function(option) { ;
__p += '\n                <li>\n                    <a href="#" data-value="' +
__e( option.value ) +
'">\n                        ' +
__e( option.text ) +
'\n                    </a>\n                </li>\n            ';
 }); ;
__p += '\n        </ul>\n    </div>\n</div>';

}
return __p
},
"success": function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="success">\n    ' +
((__t = ( message )) == null ? '' : __t) +
'\n</div>';

}
return __p
}};
(function() {
  var About, AjaxForm, Chat, Conference, Contact, Forms, Global, Homepage, Map, Nav, Page, PageSection, ProjectPlanner, RegisterInterest, ScrollTo, Template, Work,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  PageSection = (function() {
    function PageSection() {}

    PageSection.prototype.sizeToWindow = function(el, minus) {
      var $section, height;
      if (minus == null) {
        minus = 0;
      }
      $section = $(el);
      height = $(window).outerHeight();
      height = height - minus;
      return $section.height(height);
    };

    return PageSection;

  })();

  AjaxForm = (function() {
    function AjaxForm(data1) {
      var __defaults;
      this.data = data1 != null ? data1 : {};
      __defaults = {
        el: false,
        fail: function() {},
        done: function() {},
        always: function() {}
      };
      if (!this.data.el) {
        return;
      }
      this.data = $.extend(true, __defaults, this.data);
      $(document).on('submit', this.data.el, (function(_this) {
        return function(e) {
          e.preventDefault();
          return _this.formSubmitted(e);
        };
      })(this));
    }

    AjaxForm.prototype.formSubmitted = function(e) {
      var $form, $submit;
      $form = $(e.currentTarget);
      $submit = $form.find('[type=submit]');
      if (!$submit.data('oldText')) {
        $submit.data('oldText', $submit.html());
      }
      $submit.html('<i class="fa fa-spinner fa-spin"></i>');
      return this.submitForm(e);
    };

    AjaxForm.prototype.submitForm = function(e) {
      var $form, $request, data;
      $form = $(e.currentTarget);
      data = $form.serialize();
      $form.find(':input').attr('disabled', true);
      $form.find('.custom-select').addClass('disabled');
      $request = $.ajax({
        url: $form.attr('action'),
        data: data,
        type: 'POST',
        headers: {
          action: $form.data('action')
        }
      });
      $form.find(':input').attr('disabled', true);
      $form.find('.custom-select').addClass('disabled');
      $request.done(this.data.done);
      $request.fail(this.data.fail);
      return $request.always(this.data.always);
    };

    return AjaxForm;

  })();

  Chat = (function() {
    Chat.prototype.offline = false;

    function Chat() {
      this.registerEvents();
    }

    Chat.prototype.registerEvents = function() {
      $(document).ready((function(_this) {
        return function() {
          _this.checkOnlineStatus();
          if (_this.getCookie('chat_open') && _this.getCookie('chat_url')) {
            return _this.openChat();
          }
        };
      })(this));
      return $(document).on('click', '[data-open-chat]', (function(_this) {
        return function(e) {
          if (!_this.offline) {
            e.preventDefault();
            return _this.open();
          }
        };
      })(this));
    };

    Chat.prototype.openChat = function() {
      return $.fancybox.open({
        href: this.getCookie('chat_url'),
        type: 'iframe',
        beforeShow: (function(_this) {
          return function() {
            return _this.setCookie('chat_open', true);
          };
        })(this),
        beforeClose: (function(_this) {
          return function() {
            return _this.deleteCookie('chat_open');
          };
        })(this)
      });
    };

    Chat.prototype.checkOnlineStatus = function() {
      return $.ajax({
        url: "https://support-chat.netsells.co.uk/",
        type: 'GET',
        success: (function(_this) {
          return function(data, textStatus, jqXHR) {
            if (data.error) {
              return _this.setChatOffline();
            }
          };
        })(this),
        error: (function(_this) {
          return function(jqXHR, textStatus, errorThrown) {
            return _this.setChatOffline();
          };
        })(this),
        complete: (function(_this) {
          return function() {
            return _this.chatStatusLoaded();
          };
        })(this)
      });
    };

    Chat.prototype.chatStatusLoaded = function() {
      return $('body').addClass('chat-loaded');
    };

    Chat.prototype.setChatOffline = function() {
      this.offline = true;
      $('#live-chat').addClass('offline');
      this.deleteCookie('chat_url');
      return this.deleteCookie('chat_open');
    };

    Chat.prototype.setChatOnline = function() {
      this.offline = false;
      return $('#live-chat').removeClass('offline');
    };

    Chat.prototype.open = function() {
      if (this.getCookie('chat_url')) {
        this.openChat();
        return;
      }
      return $.ajax({
        url: "https://support-chat.netsells.co.uk/",
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          page_url: window.location.href
        },
        success: (function(_this) {
          return function(data, textStatus, jqXHR) {
            if (data.error) {
              return _this.setChatOffline();
            } else {
              _this.setChatOnline();
              _this.setCookie('chat_url', data.guest_access_url);
              return _this.openChat();
            }
          };
        })(this),
        error: (function(_this) {
          return function(jqXHR, textStatus, errorThrown) {
            return _this.setChatOffline();
          };
        })(this)
      });
    };

    Chat.prototype.setCookie = function(name, value, days) {
      var date, expires;
      if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      return document.cookie = name + "=" + value + expires + "; path=/";
    };

    Chat.prototype.getCookie = function(name) {
      var c, ca, i, nameEQ;
      nameEQ = name + "=";
      ca = document.cookie.split(";");
      i = 0;
      while (i < ca.length) {
        c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
        i++;
      }
      return null;
    };

    Chat.prototype.deleteCookie = function(name) {
      return this.setCookie(name, "", -1);
    };

    return Chat;

  })();

  Forms = (function() {
    function Forms() {
      this.constructFormComponents();
    }

    Forms.prototype.constructFormComponents = function() {
      var component, j, len, ref, results;
      ref = ['select'];
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        component = ref[j];
        results.push(this[component]());
      }
      return results;
    };

    Forms.prototype.select = function() {
      $(document).on('click', '.backdrop', (function(_this) {
        return function(e) {
          return _this.closeSelect(e);
        };
      })(this));
      $(document).off('click.customSelect').on('click.customSelect', '[data-toggle-custom-select]', function(e) {
        e.preventDefault();
        if (!$(e.currentTarget).closest('.custom-select').next('select').is('[disabled]')) {
          return $(e.currentTarget).closest('.custom-select').toggleClass('open');
        } else {
          return $(e.currentTarget).closest('.custom-select').removeClass('open');
        }
      });
      $(document).off('click.customSelectOption').on('click.customSelectOption', '.custom-select [data-value]', (function(_this) {
        return function(e) {
          var $option, index;
          e.preventDefault();
          $option = $(e.currentTarget).closest('li');
          index = $option.index();
          return _this.selectOption(e, index);
        };
      })(this));
      return $('select').each((function(_this) {
        return function(i, el) {
          return _this.setupSelect(el);
        };
      })(this));
    };

    Forms.prototype.selectOption = function(e, index) {
      var $clicked, $customSelect, $select, optionHeight;
      $clicked = $(e.currentTarget);
      $customSelect = $clicked.closest('.custom-select');
      $select = $customSelect.siblings('select');
      if (!$select.is('[disabled]')) {
        $select.find('option').attr('selected', false).eq(index).attr('selected', true);
        optionHeight = $customSelect.find('.selector li').outerHeight();
        if ($select.data('placeholder')) {
          index++;
        }
        $customSelect.find('.selector ul').css({
          'margin-top': 0 - (optionHeight * index)
        });
      }
      return this.closeSelect(e);
    };

    Forms.prototype.closeSelect = function(e) {
      var $clicked, $customSelect;
      $clicked = $(e.currentTarget);
      $customSelect = $clicked.closest('.custom-select');
      return $customSelect.removeClass('open');
    };

    Forms.prototype.setupSelect = function(el) {
      var elOptions, option, options;
      elOptions = $(el).find('option');
      options = (function() {
        var j, len, results;
        results = [];
        for (j = 0, len = elOptions.length; j < len; j++) {
          option = elOptions[j];
          results.push({
            text: $(option).text(),
            value: $(option).attr('value')
          });
        }
        return results;
      })();
      return $(el).before(JST['select']({
        options: options,
        placeholder: $(el).data('placeholder')
      }));
    };

    return Forms;

  })();

  Global = (function() {
    Global.prototype.PageSection = new PageSection;

    function Global() {
      this.events();
    }

    Global.prototype.events = function() {
      $(document).ready((function(_this) {
        return function() {
          return _this.documentReady();
        };
      })(this));
      if ($('[data-parallax]').length) {
        $(window).scroll((function(_this) {
          return function() {
            return _this.onWindowScrollParallax();
          };
        })(this));
      }
      if ($('section.header').length) {
        $(window).resize((function(_this) {
          return function() {
            return _this.sizeHeader();
          };
        })(this));
        return $(window).load((function(_this) {
          return function() {
            return _this.sizeHeader();
          };
        })(this));
      }
    };

    Global.prototype.onWindowScrollParallax = function() {
      var $parallax, amount, data, scrolled, speed;
      $parallax = $('[data-parallax]');
      data = $parallax.data('parallax');
      speed = data.speed;
      scrolled = $(window).scrollTop();
      amount = Math.ceil(speed * scrolled);
      if (amount < 0) {
        amount = 0;
      }
      return $parallax.css({
        'transform': "translate3d(0, " + amount + "px, 0)"
      });
    };

    Global.prototype.placeholders = function() {
      return $('input, textarea').placeholder();
    };

    Global.prototype.documentReady = function() {
      this.waypoints();
      setTimeout((function() {
        return $('body').addClass('ready');
      }), 10);
      if ($('section.header').length) {
        this.sizeHeader();
      }
      return this.floatLabel();
    };

    Global.prototype.floatLabel = function() {
      return $('input.floatlabel').floatlabel({
        labelEndTop: '-30px',
        slideInput: false
      });
    };

    Global.prototype.waypoints = function() {
      return $('[data-waypoint]').each(function(i, el) {
        var data;
        data = $(el).data('waypoint');
        return $(el).waypoint({
          handler: function(direction) {
            return $(el).addClass('inview');
          },
          offset: data.offset
        });
      });
    };

    Global.prototype.sizeHeader = function() {
      var $header, height;
      $header = $('section.header[data-size-header-to-window] .o');
      height = $('header').innerHeight() * 2;
      return this.PageSection.sizeToWindow($header, height);
    };

    Global.prototype.setCookie = function(name, value, days) {
      var date, expires;
      if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      return document.cookie = name + "=" + value + expires + "; path=/";
    };

    Global.prototype.getCookie = function(name) {
      var c, ca, i, nameEQ;
      nameEQ = name + "=";
      ca = document.cookie.split(";");
      i = 0;
      while (i < ca.length) {
        c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
        i++;
      }
      return null;
    };

    Global.prototype.deleteCookie = function(name) {
      return this.setCookie(name, "", -1);
    };

    return Global;

  })();

  Map = (function() {
    function Map(el1) {
      this.el = el1;
      this.initMap = bind(this.initMap, this);
      this.registerEvents();
    }

    Map.prototype.registerEvents = function() {
      return window.mapReady = this.initMap;
    };

    Map.prototype.initMap = function() {
      var image, latLng, marker, shape;
      latLng = {
        lat: parseFloat(Agency.map.lat),
        lng: parseFloat(Agency.map.long)
      };
      image = {
        url: '/images/app/shared/marker.png',
        size: new google.maps.Size(51, 70),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 35)
      };
      shape = {
        coords: [24, 67, 41, 51, 47, 39, 49, 26, 47, 15, 38, 5, 25, 0, 12, 4, 3, 14, 0, 26, 3, 42, 14, 57],
        type: 'poly'
      };
      this.map = new google.maps.Map($(this.el)[0], {
        zoom: 12,
        center: latLng,
        scrollwheel: false,
        draggable: false,
        disableDefaultUI: true
      });
      marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: 'Netsells Ltd.',
        animation: google.maps.Animation.DROP,
        icon: image,
        shape: shape
      });
    };

    return Map;

  })();

  Nav = (function() {
    function Nav() {
      this.registerEvents();
    }

    Nav.prototype.registerEvents = function() {
      this.setup();
      $(document).on('click', '[data-toggle-nav]', (function(_this) {
        return function(e) {
          return _this.onToggleNavClick(e);
        };
      })(this));
      $(document).on('click', '.body-overlay', (function(_this) {
        return function() {
          return _this.closeNav();
        };
      })(this));
      return $(window).on('resize', (function(_this) {
        return function() {
          return _this.closeNav();
        };
      })(this));
    };

    Nav.prototype.setup = function() {
      return $('body').wrapInner('<div class="body-container"/>').append(this.render()).append('<div class="body-overlay" />').wrapInner('<div class="body-overflow" />');
    };

    Nav.prototype.render = function() {
      var item, navTemplate;
      this.generateNavItems('header nav ul');
      navTemplate = '<nav id="mobile-menu"><ul>%n</ul></nav>';
      return navTemplate.replace('%n', ((function() {
        var j, len, ref, results;
        ref = this.items;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          item = ref[j];
          results.push(this.renderItem(item));
        }
        return results;
      }).call(this)).join(""));
    };

    Nav.prototype.generateNavItems = function(nav) {
      var $items, $nav;
      this.items = [];
      $nav = $(nav);
      $items = $nav.find('a');
      return $items.each((function(_this) {
        return function(i, el) {
          var item;
          if ($(el).attr('href') === '#') {
            return false;
          }
          item = {
            text: $(el).text(),
            link: $(el).attr('href')
          };
          return _this.items.push(item);
        };
      })(this));
    };

    Nav.prototype.renderItem = function(item) {
      var icon;
      if (item['icon']) {
        icon = "<span class=\"icon\"><i class=\"fa " + item['icon'] + "\"></i></span>";
      } else {
        icon = "";
      }
      return "<li> <a href=\"" + item['link'] + "\"> " + item['text'] + " " + icon + " </a> </li>";
    };

    Nav.prototype.onToggleNavClick = function(e) {
      e.preventDefault();
      if ($('body.nav-open').length) {
        return this.closeNav();
      } else {
        return this.openNav();
      }
    };

    Nav.prototype.openNav = function() {
      $('[data-toggle-nav]').first().addClass('open');
      return $('body').addClass('nav-open');
    };

    Nav.prototype.closeNav = function() {
      $('[data-toggle-nav]').first().removeClass('open');
      return $('body').removeClass('nav-open');
    };

    return Nav;

  })();

  Page = (function() {
    Page.prototype.isIn = false;

    Page.prototype.config = {
      type: 'page',
      component: ''
    };

    function Page(extended) {
      this.extended = extended != null ? extended : {};
      if (_.size(this.extended)) {
        $.extend(this.config, this.extended);
      }
    }

    Page.prototype.is = function(slug) {
      return $('body').hasClass(this.config.type + "-" + this.config.component + slug);
    };

    Page.prototype["in"] = function(slugs) {
      var fn, j, len, slug;
      this.isIn = false;
      slugs = slugs instanceof Array ? slugs : [slugs];
      fn = (function(_this) {
        return function(slug) {
          if (_this.is(slug)) {
            return _this.isIn = true;
          }
        };
      })(this);
      for (j = 0, len = slugs.length; j < len; j++) {
        slug = slugs[j];
        fn(slug);
      }
      return this.isIn;
    };

    Page.prototype.segment = function(id) {
      var segments;
      segments = window.location.pathname.split('/');
      if (segments[id] != null) {
        return segments[id];
      } else {
        return false;
      }
    };

    Page.prototype.segmentMatch = function(id, match) {
      return this.segment(id) === match;
    };

    return Page;

  })();

  RegisterInterest = (function() {
    function RegisterInterest() {
      new AjaxForm({
        el: 'section.laracon form',
        done: this.doneResponse,
        fail: this.failResponse,
        always: this.alwaysResponse
      });
    }

    RegisterInterest.prototype.doneResponse = function(data, textStatus, jqXHR) {
      var $form, $section;
      $form = $('section.laracon form');
      $section = $form.closest('section.laracon');
      $section.addClass('submitted');
      return setTimeout((function() {
        $section.find('h3').text('Thanks for your interest');
        $section.find('p:first').text('We\'ll let you know when we have more information to share');
        $form.parent().slideUp();
        return $section.addClass('show-success');
      }), 750);
    };

    RegisterInterest.prototype.alwaysReponse = function(jqXHR, textStatus, errorThrown) {
      var $form, $submit;
      $form = $('section.laracon form');
      $submit = $form.find('[type=submit]');
      $submit.html($submit.data('oldText'));
      $form.find(':input').attr('disabled', false);
      return $form.find('.custom-select').removeClass('disabled');
    };

    RegisterInterest.prototype.failResponse = function(data) {
      return sweetAlert("Oops...", "Something went wrong!", "error");
    };

    return RegisterInterest;

  })();

  ScrollTo = (function() {
    function ScrollTo() {
      this.events();
    }

    ScrollTo.prototype.events = function() {
      $('html, body').bind("scroll mousedown DOMMouseScroll mousewheel keyup", (function(_this) {
        return function(e) {
          if (e.which > 0 || e.type === "mousedown" || e.type === "mousewheel") {
            if ($('html, body').is(':animated')) {
              return $('html, body').stop();
            }
          }
        };
      })(this));
      $(window).on('hashchange', (function(_this) {
        return function() {
          Nav.closeNav();
          return _this.scrollTo();
        };
      })(this));
      return $(document).ready((function(_this) {
        return function() {
          return setTimeout((function() {
            return _this.scrollTo();
          }), 1000);
        };
      })(this));
    };

    ScrollTo.prototype.scrollTo = function() {
      var hash;
      hash = window.location.hash;
      if (hash !== '') {
        if (this.scrollHashExists(hash)) {
          return this.animateScrollTo(hash);
        }
      }
    };

    ScrollTo.prototype.scrollHashExists = function(hash) {
      return $("[data-scrollto='" + hash + "']").length;
    };

    ScrollTo.prototype.animateScrollTo = function(hash) {
      var navHeight, scrollDestination;
      scrollDestination = $("[data-scrollto='" + hash + "']").offset().top;
      navHeight = $('header').height();
      scrollDestination -= navHeight;
      return $('html, body').animate({
        scrollTop: scrollDestination
      }, scrollDestination * .5);
    };

    return ScrollTo;

  })();

  Page = (function() {
    Page.prototype.isIn = false;

    Page.prototype.config = {
      type: 'page',
      component: ''
    };

    function Page(extended) {
      this.extended = extended != null ? extended : {};
      if (_.size(this.extended)) {
        $.extend(this.config, this.extended);
      }
    }

    Page.prototype.is = function(slug) {
      return $('body').hasClass(this.config.type + "-" + this.config.component + slug);
    };

    Page.prototype["in"] = function(slugs) {
      var fn, j, len, slug;
      this.isIn = false;
      slugs = slugs instanceof Array ? slugs : [slugs];
      fn = (function(_this) {
        return function(slug) {
          if (_this.is(slug)) {
            return _this.isIn = true;
          }
        };
      })(this);
      for (j = 0, len = slugs.length; j < len; j++) {
        slug = slugs[j];
        fn(slug);
      }
      return this.isIn;
    };

    Page.prototype.segment = function(id) {
      var segments;
      segments = window.location.pathname.split('/');
      if (segments[id] != null) {
        return segments[id];
      } else {
        return false;
      }
    };

    Page.prototype.segmentMatch = function(id, match) {
      return this.segment(id) === match;
    };

    return Page;

  })();

  About = (function() {
    function About() {
      this.events();
    }

    About.prototype.events = function() {};

    return About;

  })();

  Conference = (function() {
    function Conference() {
      this.events();
    }

    Conference.prototype.events = function() {
      return $(document).ready((function(_this) {
        return function() {
          return new Map('#map');
        };
      })(this));
    };

    return Conference;

  })();

  Contact = (function() {
    function Contact() {
      this.events();
      new AjaxForm({
        el: 'section.content form',
        done: this.doneResponse,
        fail: this.failResponse,
        always: this.alwaysResponse
      });
    }

    Contact.prototype.events = function() {
      return $(document).ready((function(_this) {
        return function() {
          return new Map('#map');
        };
      })(this));
    };

    Contact.prototype.doneResponse = function(data, textStatus, jqXHR) {
      $('.success').remove();
      $('section.content .container').prepend(JST['success']({
        message: Agency.form.success
      }));
      return $("html, body").animate({
        scrollTop: $('section.content').offset().top - 90
      }, {
        complete: function() {
          return $('.success').slideDown();
        }
      });
    };

    Contact.prototype.alwaysResponse = function(jqXHR, textStatus, errorThrown) {
      var $form, $submit;
      $form = $('section.content form');
      $submit = $form.find('[type=submit]');
      $submit.html($submit.data('oldText'));
      $form.find(':input').attr('disabled', false);
      return $form.find('.custom-select').removeClass('disabled');
    };

    Contact.prototype.failResponse = function(data) {
      return sweetAlert("Oops...", "Something went wrong!", "error");
    };

    return Contact;

  })();

  Homepage = (function() {
    Homepage.prototype.PageSection = new PageSection;

    function Homepage() {
      this.events();
    }

    Homepage.prototype.events = function() {
      return $(document).ready((function(_this) {
        return function() {
          _this.onDocumentReady();
          return _this.typeHeader();
        };
      })(this));
    };

    Homepage.prototype.onDocumentReady = function() {
      return this.sizeLaraconSection();
    };

    Homepage.prototype.sizeLaraconSection = function() {
      var $section;
      $section = $('section.conference .o');
      return this.PageSection.sizeToWindow($section);
    };

    Homepage.prototype.typeHeader = function() {
      var $header, original, text;
      text = [];
      $header = $("section.header h1");
      original = $header.html();
      text.push(original.replace('Hello', 'Hello^1000'));
      return $header.typed({
        strings: text,
        typeSpeed: 30,
        contentType: 'html',
        cursorChar: '',
        callback: function() {
          return $("section.header").addClass('ready');
        }
      });
    };

    return Homepage;

  })();

  ProjectPlanner = (function() {
    function ProjectPlanner() {
      new AjaxForm({
        el: 'section.content form',
        done: this.doneResponse,
        fail: this.failResponse,
        always: this.alwaysResponse
      });
    }

    ProjectPlanner.prototype.doneResponse = function(data, textStatus, jqXHR) {
      $('.success').remove();
      $('section.content .container').prepend(JST['success']({
        message: Agency.form.success
      }));
      return $("html, body").animate({
        scrollTop: $('section.content').offset().top - 90
      }, {
        complete: function() {
          return $('.success').slideDown();
        }
      });
    };

    ProjectPlanner.prototype.alwaysResponse = function(jqXHR, textStatus, errorThrown) {
      var $form, $submit;
      $form = $('section.content form');
      $submit = $form.find('[type=submit]');
      $submit.html($submit.data('oldText'));
      $form.find(':input').attr('disabled', false);
      return $form.find('.custom-select').removeClass('disabled');
    };

    ProjectPlanner.prototype.failResponse = function(data) {
      return sweetAlert("Oops...", "Something went wrong!", "error");
    };

    return ProjectPlanner;

  })();

  Work = (function() {
    function Work() {
      this.events();
    }

    Work.prototype.events = function() {};

    return Work;

  })();

  Global = new Global;

  Template = new Page({
    type: 'template'
  });

  Nav = new Nav;

  Forms = new Forms;

  Chat = new Chat;

  if ($('[data-scrollto]').length) {
    new ScrollTo;
  }

  if (Template.is('homepage')) {
    new Homepage;
  }

  if (Template.is('about')) {
    new About;
  }

  if (Template.is('contact')) {
    new Contact;
  }

  if (Template.is('work')) {
    new Work;
  }

  if (Template.is('conference')) {
    new Conference;
  }

  if (Template.is('projectplanner')) {
    new ProjectPlanner;
  }

  if ($('section.laracon').length) {
    new RegisterInterest;
  }

}).call(this);


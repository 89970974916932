class Map

    constructor: (@el) ->
        @registerEvents()

    registerEvents: ->
        window.mapReady = @initMap

    initMap: =>
        latLng = 
            lat: parseFloat(Agency.map.lat)
            lng: parseFloat(Agency.map.long)

        image =
            url:    '/images/app/shared/marker.png'
            size:   new google.maps.Size(51, 70)
            origin: new google.maps.Point(0, 0)
            anchor: new google.maps.Point(0, 35)

        shape = 
            coords: [24, 67, 41, 51, 47, 39, 49, 26, 47, 15, 38, 5, 25, 0, 12, 4, 3, 14, 0, 26, 3, 42, 14, 57]
            type:   'poly'

        @map = new google.maps.Map $(@el)[0], 
            zoom:             12
            center:           latLng
            scrollwheel:      false
            draggable:        false
            disableDefaultUI: true

        marker = new google.maps.Marker
            position:  latLng
            map:       @map
            title:     'Netsells Ltd.'
            animation: google.maps.Animation.DROP
            icon:      image
            shape:     shape

        return
class Forms

    constructor: ->
        @constructFormComponents()

    constructFormComponents: ->
        for component in ['select']
            @[component]()

    select: ->
        $(document).on 'click', '.backdrop', (e) =>
            @closeSelect(e)

        $(document)
            .off('click.customSelect')
            .on 'click.customSelect', '[data-toggle-custom-select]', (e) ->
                e.preventDefault()
                if !$(e.currentTarget).closest('.custom-select').next('select').is '[disabled]'
                    $(e.currentTarget).closest('.custom-select').toggleClass 'open'
                else
                    $(e.currentTarget).closest('.custom-select').removeClass 'open'

        $(document)
            .off('click.customSelectOption')
            .on 'click.customSelectOption', '.custom-select [data-value]', (e) =>
                e.preventDefault()
                $option = $(e.currentTarget).closest('li')
                index = $option.index()

                @selectOption(e, index)

        $('select').each (i, el) =>
            @setupSelect(el)

    selectOption: (e, index) ->
        # Get the elements we want to work with
        $clicked = $(e.currentTarget)
        $customSelect = $clicked.closest('.custom-select')
        $select = $customSelect.siblings('select')

        if !$select.is '[disabled]'
            # Select the right one in the html 
            # select element via the index
            $select.find('option').attr 'selected', false
                .eq(index).attr 'selected', true
            # Get the height of an option so we 
            # can scroll correctly
            optionHeight = $customSelect.find('.selector li').outerHeight()
            # If there's a placeholder, we want 
            # to offset by one
            if $select.data 'placeholder'
                index++
            # Scroll to the selected option
            $customSelect.find('.selector ul').css
                'margin-top': 0 - (optionHeight * index)

        @closeSelect(e)

    # Close the selector
    closeSelect: (e) ->
        $clicked = $(e.currentTarget)
        $customSelect = $clicked.closest('.custom-select')
        $customSelect.removeClass 'open'

    setupSelect: (el) ->
        elOptions = $(el).find('option')
        # Populate the options into an object 
        # for our template
        options = for option in elOptions
            {
                text:  $(option).text()
                value: $(option).attr 'value'
            }
        # Render the template after our select
        $(el).before JST['select']({ 
            options:     options
            placeholder: $(el).data 'placeholder'
        })
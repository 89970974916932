class ProjectPlanner

    constructor: ->
        new AjaxForm({
            el:     'section.content form'
            done:   @doneResponse
            fail:   @failResponse
            always: @alwaysResponse
        })

    doneResponse: (data, textStatus, jqXHR) ->
        $('.success').remove()

        $('section.content .container').prepend JST['success']({ message: Agency.form.success })
        $("html, body").animate({ scrollTop: $('section.content').offset().top - 90 }, 
            complete: ->
                $('.success').slideDown()
        )
        

    alwaysResponse: (jqXHR, textStatus, errorThrown) ->
        $form = $('section.content form')
        $submit = $form.find('[type=submit]')

        $submit.html $submit.data('oldText')

        $form.find(':input').attr 'disabled', false
        $form.find('.custom-select').removeClass 'disabled'

    failResponse: (data) ->
        sweetAlert("Oops...", "Something went wrong!", "error")

Global      = new Global
Template    = new Page({ type: 'template' })
Nav         = new Nav
Forms       = new Forms
Chat        = new Chat

if $('[data-scrollto]').length
    new ScrollTo

# Pages
if Template.is 'homepage'
    new Homepage

if Template.is 'about'
    new About

if Template.is 'contact'
    new Contact

if Template.is 'work'
    new Work

if Template.is 'conference'
    new Conference

if Template.is 'projectplanner'
    new ProjectPlanner

if $('section.laracon').length
    new RegisterInterest


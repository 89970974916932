class Global

    PageSection: new PageSection

    constructor: ->
        @events()

    events: ->
        # Setup global elements
        $(document).ready =>
            @documentReady()
        # Setup parallax element if it exists
        if $('[data-parallax]').length
            $(window).scroll =>
                @onWindowScrollParallax()

        if $('section.header').length
            $(window).resize =>
                @sizeHeader()
            $(window).load =>
                @sizeHeader()

    # Setup parallax based on data attributes
    onWindowScrollParallax: ->
        $parallax = $('[data-parallax]')
        # Get the data passed in
        data = $parallax.data 'parallax'
        # The speed is a ratio of the distance scrolled
        # e.g. 0.5 would be 50% the distance of the page scroll
        speed = data.speed

        scrolled = $(window).scrollTop()

        amount = Math.ceil(speed * scrolled)

        if amount < 0
            amount = 0

        $parallax.css
            'transform':  "translate3d(0, #{amount}px, 0)"

    # Setup placeholders for legacy browsers
    placeholders: ->
        $('input, textarea').placeholder()

    # Document is ready, add the class
    documentReady: ->
        @waypoints()
        # Add delay as a fix for loading lag
        setTimeout (->
            $('body').addClass 'ready'
        ), 10
        if $('section.header').length
            @sizeHeader()
        @floatLabel()

    # Setup floatlabel
    floatLabel: ->
        $('input.floatlabel').floatlabel
            labelEndTop: '-30px'
            slideInput:  false

    # Generic waypoints controller
    waypoints: ->
        # Loop the waypoints
        $('[data-waypoint]').each (i, el) ->
            # Get the data
            data = $(el).data 'waypoint'
            # Setup the waypoint
            $(el).waypoint
                handler: (direction) ->
                    $(el).addClass 'inview'
                offset: data.offset

    sizeHeader: ->
        # Get the header
        $header = $('section.header[data-size-header-to-window] .o')
        # Calculate twice the nav height an absolute centre
        height = $('header').innerHeight() * 2
        # Size it.
        @PageSection.sizeToWindow($header, height)


    setCookie: (name, value, days) ->
        if days
            date = new Date()
            date.setTime date.getTime() + (days * 24 * 60 * 60 * 1000)
            expires = "; expires=" + date.toGMTString()
        else
            expires = ""
        document.cookie = name + "=" + value + expires + "; path=/"

    getCookie: (name) ->
        nameEQ = name + "="
        ca = document.cookie.split(";")
        i = 0

        while i < ca.length
            c = ca[i]
            c = c.substring(1, c.length)  while c.charAt(0) is " "
            return c.substring(nameEQ.length, c.length)  if c.indexOf(nameEQ) is 0
            i++
        null

    deleteCookie: (name) ->
        @setCookie(name, "", -1)
class Homepage

    PageSection: new PageSection

    constructor: ->
        @events()

    events: ->
        $(document).ready =>
            @onDocumentReady()
            @typeHeader()

    onDocumentReady: ->
        @sizeLaraconSection()

    sizeLaraconSection: ->
        # Get the section
        $section = $('section.conference .o')
        # Size it.
        @PageSection.sizeToWindow($section)

    typeHeader: ->
        text = []
        # $("section.header h1").html($("section.header h1").html().replace('Hello', 'Hello^1000'))
        $header = $("section.header h1")
        original = $header.html()
        # text.push($header.html().replace('developers', 'artisans'))
        # text.push($header.html().replace('developers', 'lovers'))
        text.push(original.replace('Hello', 'Hello^1000'))

        $header.typed
            strings:     text
            typeSpeed:   30
            contentType: 'html'
            cursorChar:  ''
            callback:    ->
                $("section.header").addClass 'ready'


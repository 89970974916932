class RegisterInterest

    constructor: ->
        new AjaxForm({
            el:     'section.laracon form'
            done:   @doneResponse
            fail:   @failResponse
            always: @alwaysResponse
        })

    doneResponse: (data, textStatus, jqXHR) ->
        $form = $('section.laracon form')
        $section = $form.closest('section.laracon')
        $section.addClass 'submitted'

        setTimeout (->
            $section.find('h3').text 'Thanks for your interest'
            $section.find('p:first').text 'We\'ll let you know when we have more information to share'
            $form.parent().slideUp()
            $section.addClass 'show-success'
        ), 750

    alwaysReponse: (jqXHR, textStatus, errorThrown) ->
        $form = $('section.laracon form')
        $submit = $form.find('[type=submit]')

        $submit.html $submit.data('oldText')

        $form.find(':input').attr 'disabled', false
        $form.find('.custom-select').removeClass 'disabled'

    failResponse: (data) ->
        sweetAlert("Oops...", "Something went wrong!", "error")







class AjaxForm

    constructor: (@data = {}) ->
        __defaults = 
            el:     false
            fail:   ->
            done:   ->
            always: ->
                
        if !@data.el
            return

        @data = $.extend(true, __defaults, @data)

        $(document).on 'submit', @data.el, (e) =>
            e.preventDefault()
            @formSubmitted(e)

    formSubmitted: (e) ->
        $form = $(e.currentTarget)
        $submit = $form.find('[type=submit]')

        if !$submit.data 'oldText'
            $submit.data 'oldText', $submit.html()

        $submit.html '<i class="fa fa-spinner fa-spin"></i>'

        @submitForm(e)

    submitForm: (e) ->
        $form = $(e.currentTarget)

        data = $form.serialize()

        $form.find(':input').attr 'disabled', true
        $form.find('.custom-select').addClass 'disabled'

        $request = $.ajax
            url:  $form.attr 'action'
            data: data
            type: 'POST'
            headers:
                action: $form.data 'action'

        $form.find(':input').attr 'disabled', true
        $form.find('.custom-select').addClass 'disabled'

        $request.done @data.done
        $request.fail @data.fail
        $request.always @data.always




class Chat

    offline: false

    constructor: ->
        @registerEvents()

    registerEvents: ->
        $(document).ready =>
            @checkOnlineStatus()
            if @getCookie('chat_open') and @getCookie('chat_url')
                @openChat()

        $(document).on 'click', '[data-open-chat]', (e) =>
            if !@offline
                e.preventDefault()
                @open()

    openChat: ->
        $.fancybox.open({
            href: @getCookie('chat_url')
            type: 'iframe'
            beforeShow: =>
                @setCookie('chat_open', true)
            beforeClose: =>
                @deleteCookie('chat_open')
        })

    checkOnlineStatus: ->
        $.ajax
            url:  "https://support-chat.netsells.co.uk/"
            type: 'GET'

            success: (data, textStatus, jqXHR) =>
                if data.error
                    @setChatOffline()

            error: (jqXHR, textStatus, errorThrown) =>
                @setChatOffline()

            complete: =>
                @chatStatusLoaded()

    chatStatusLoaded: ->
        $('body').addClass 'chat-loaded'

    setChatOffline: ->
        @offline = true
        $('#live-chat').addClass('offline')

        @deleteCookie('chat_url')
        @deleteCookie('chat_open')

    setChatOnline: ->
        @offline = false
        $('#live-chat').removeClass('offline')

    open: ->
        if @getCookie('chat_url')
            @openChat()
            return

        $.ajax
            url: "https://support-chat.netsells.co.uk/"
            xhrFields: 
                withCredentials: true
            type: 'POST'
            data:
                page_url: window.location.href

            success: (data, textStatus, jqXHR) =>
                if data.error
                    @setChatOffline()
                else
                    @setChatOnline()
                    @setCookie('chat_url', data.guest_access_url)
                    @openChat()

            error: (jqXHR, textStatus, errorThrown) =>
                @setChatOffline()


    setCookie: (name, value, days) ->
        if days
            date = new Date()
            date.setTime date.getTime() + (days * 24 * 60 * 60 * 1000)
            expires = "; expires=" + date.toGMTString()
        else
            expires = ""
        document.cookie = name + "=" + value + expires + "; path=/"

    getCookie: (name) ->
        nameEQ = name + "="
        ca = document.cookie.split(";")
        i = 0

        while i < ca.length
            c = ca[i]
            c = c.substring(1, c.length)  while c.charAt(0) is " "
            return c.substring(nameEQ.length, c.length)  if c.indexOf(nameEQ) is 0
            i++
        null

    deleteCookie: (name) ->
        @setCookie(name, "", -1)

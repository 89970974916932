class PageSection

    constructor: ->

    sizeToWindow: (el, minus = 0) ->
        # Get the page section
        $section = $(el)
        # Get window height
        height = $(window).outerHeight()
        # Subtract the specified amount
        height = height - minus
        # Set it
        $section.height(height)

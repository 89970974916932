this.JST = {"select": function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="custom-select">\n    <div class="backdrop"></div>\n    <div class="selector" data-toggle-custom-select>\n        <ul>\n            ';
 if (placeholder) { ;
__p += '\n                <li class="placeholder">\n                    ' +
__e( placeholder ) +
'\n                </li>\n            ';
 } ;
__p += '\n            ';
 _.each(options, function(option) { ;
__p += '\n                <li>\n                    ' +
__e( option.text ) +
'\n                </li>\n            ';
 }); ;
__p += '\n        </ul>\n        <div class="arrow">\n            <i class="fa fa-chevron-down"></i>\n        </div>\n    </div>\n    <div class="dropdown">\n        <ul>\n            ';
 _.each(options, function(option) { ;
__p += '\n                <li>\n                    <a href="#" data-value="' +
__e( option.value ) +
'">\n                        ' +
__e( option.text ) +
'\n                    </a>\n                </li>\n            ';
 }); ;
__p += '\n        </ul>\n    </div>\n</div>';

}
return __p
},
"success": function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="success">\n    ' +
((__t = ( message )) == null ? '' : __t) +
'\n</div>';

}
return __p
}};